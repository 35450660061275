export interface ImportMetaEnv {
  VITE_ENV_DISABLE_AUTHENTICATION: string;
  VITE_ENV_APP_VERSION: string;
  VITE_ENV_APP_HOSTING_ENVIRONMENT: string;
  VITE_ENV_POWERBI_LINK: string;
  VITE_ENV_FAQ_LINK: string;

  [key: string]: unknown;
}

export interface ImportMeta {
  url: string;

  readonly env: ImportMetaEnv;
}

export const DISABLE_AUTHENTICATION = import.meta.env
  .VITE_ENV_DISABLE_AUTHENTICATION;

export const APP_VERSION = import.meta.env.VITE_ENV_APP_VERSION;
export const HOSTING_ENVIRONMENT = import.meta.env
  .VITE_ENV_APP_HOSTING_ENVIRONMENT;

export const POWERBI_LINK = import.meta.env.VITE_ENV_POWERBI_LINK;

export const FAQ_LINK = import.meta.env.VITE_ENV_FAQ_LINK;
