import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import {
  AccountInfo,
  BrowserAuthError,
  EndSessionRequest,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { authConfig$, authConfigSettings$ } from './stores/auth.repository';
import { authenticationIsDisabled } from './TestHelper';
import { setAuth, setAuth$ } from './stores/auth.effects';
import { dispatch } from '@ngneat/effects';
import { useEffects } from '@ngneat/effects-hooks';
import { useObservable } from '@ngneat/react-rxjs';

interface Props {
  children: React.ReactNode;
}

export const MsalAuthenticator: FC<Props> = (props: Props) => {
  useEffects([setAuth$]);

  const [authConfigSettings] = useObservable(authConfigSettings$);
  const [authConfig] = useObservable(authConfig$);

  const [msalAuth] = useState(new PublicClientApplication(authConfig));

  const [account, setAccount] = useState<AccountInfo | undefined>(getAccount());

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function hasError() {
    return errorMessage != null && errorMessage !== '';
  }

  async function login() {
    try {
      !authenticationIsDisabled() &&
        (await msalAuth
          .loginRedirect({
            scopes: getScope(),
          })
          .catch(console.log));
    } catch (e) {
      !(e instanceof BrowserAuthError) && console.error(e);
    }
  }

  function logout() {
    msalAuth
      .logoutRedirect({ account: account } as EndSessionRequest)
      .finally();
  }

  function getAccount() {
    try {
      return msalAuth.getAllAccounts()?.[0] ?? undefined;
    } catch (e) {
      setErrorMessage(e as string);
    }
  }

  function getScope(): string[] {
    return authConfigSettings.scope?.split(' ') ?? [''];
  }

  const getToken = async () => {
    if (!account) await login();
    else {
      const silentRequest: SilentRequest = {
        scopes: getScope(),
        forceRefresh: true,
        account: account,
      };
      try {
        const { accessToken, idToken } = await msalAuth.acquireTokenSilent(
          silentRequest
        );
        return { accessToken, idToken };
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          msalAuth
            .acquireTokenRedirect({
              ...silentRequest,
              redirectStartPage: window.location.href,
            })
            .catch(console.error);
        }
      }
    }
    return Promise.reject(new Error('getToken Error'));
  };

  useEffect(() => {
    msalAuth
      ?.handleRedirectPromise()
      .then((response) => {
        setAccount(response?.account ?? getAccount());
        setErrorMessage(null);
      })
      .catch(setErrorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalAuth]);

  useEffect(() => {
    getToken().then((token) =>
      dispatch(
        setAuth({
          ...token,
          isAuthenticated: true,
          account,
          login,
          logout,
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      getToken()
        .then((token) => {
          setAuth({
            ...token,
            isAuthenticated: true,
            account,
            login,
            logout,
          });
        })
        .catch(console.error);
    }, 1000 * 60 * 10);

    return () => clearInterval(tokenRefreshInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{hasError() ? errorMessage : props.children}</>;
};
