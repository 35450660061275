import React, { FC } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';

interface HeaderButtonProps {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const HeaderButton: FC<HeaderButtonProps> = ({
  label,
  icon,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Button
      endIcon={!isMobile && icon}
      variant="contained"
      sx={{
        [theme.breakpoints.down('md')]: {
          p: 0,
          pr: theme.spacing(1),
          pl: theme.spacing(1),
        },
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
