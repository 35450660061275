import React, { FC, useEffect, useRef } from 'react';
import { AddressCheckStepProps } from './step-props';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Checkbox,
  Divider,
  inputLabelClasses,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { dispatch } from '@ngneat/effects';
import { useObservable } from '@ngneat/react-rxjs';
import {
  headmaster$,
  originalTeachers$,
  schoolBookManagers$,
  teachers$,
  teachersLoading$,
  teacherStaff$,
  teacherStaffEditMode$,
  teacherStaffLoading$,
  teacherStaffValidationErrors$,
} from '../../store';
import { useTranslation } from 'react-i18next';
import sanitize from 'sanitize-html';
import { LoadingSkeleton, StyledTableCell } from '@verona/components';
import { TeacherStaffDto } from '@verona/address-check-public-api';
import {
  Article,
  CheckBox,
  DeleteForever,
  ExpandMore,
  Person,
  Refresh,
  Save,
} from '@mui/icons-material';
import { RegisterTeacher } from '../register-teacher';
import { LoadingButton } from '@mui/lab';
import {
  loadTeacherStaff,
  persistTeacherStaffAction,
  reloadTeachers,
  updateTeacherStaffAction,
} from '../../store/address-check/address-check.actions';

export const TeacherListPage: FC<AddressCheckStepProps> = ({
  config,
  configLoading,
  isMobile,
  isActive,
  addressCheckInstitutionId,
}) => {
  useEffect(() => {
    isActive && dispatch(loadTeacherStaff({ addressCheckInstitutionId }));
  }, [isActive]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <LoadingSkeleton loading={configLoading}>
        <Box
          dangerouslySetInnerHTML={{
            __html: sanitize(config.teacherListPageBody ?? ''),
          }}
        />
        {config.gdprNoticeEnabled && (
          <Box
            dangerouslySetInnerHTML={{
              __html: sanitize(config.teacherListPageGdprNotice ?? ''),
            }}
          />
        )}
      </LoadingSkeleton>
      <HeadmasterAutocomplete
        isMobile={isMobile}
        addressCheckInstitutionId={addressCheckInstitutionId}
      />
      <SchoolBookManagerAutocomplete
        isMobile={isMobile}
        addressCheckInstitutionId={addressCheckInstitutionId}
      />
      <TeacherListTable
        isMobile={isMobile}
        addressCheckInstitutionId={addressCheckInstitutionId}
      />
      <ActionButtons
        addressCheckInstitutionId={addressCheckInstitutionId}
        isMobile={isMobile}
      />
    </Box>
  );
};

const ActionButtons: FC<{
  addressCheckInstitutionId: string;
  isMobile?: boolean;
}> = ({ addressCheckInstitutionId, isMobile }) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherList.actions',
  });

  const [editMode] = useObservable(teacherStaffEditMode$);
  const [teacherStaff] = useObservable(teacherStaff$);

  return (
    <>
      {editMode && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            position: 'sticky',
            bottom: 10,
            gap: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color={'error'}
            variant={'contained'}
            startIcon={<DeleteForever />}
            onClick={() => {
              dispatch(
                loadTeacherStaff({
                  addressCheckInstitutionId,
                })
              );
            }}
          >
            {isMobile ? t(`discard.short.label`) : t(`discard.label`)}
          </Button>

          <Button
            color="primary"
            variant={'contained'}
            startIcon={<Save />}
            onClick={() => {
              dispatch(
                persistTeacherStaffAction({
                  addressCheckInstitutionId,
                  teacherStaffDto: teacherStaff,
                })
              );
            }}
          >
            {isMobile ? t(`save.short.label`) : t(`save.label`)}
          </Button>
        </Box>
      )}
    </>
  );
};

const HeadmasterAutocomplete: FC<{
  isMobile?: boolean;
  addressCheckInstitutionId: string;
}> = ({ isMobile, addressCheckInstitutionId }) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherList.headmaster',
  });

  const [teacherStaffLoading] = useObservable(teacherStaffLoading$);
  const [headmaster] = useObservable(headmaster$);
  const [headmasterError] = useObservable(
    teacherStaffValidationErrors$('headmaster')
  );

  const [teachers] = useObservable(teachers$);

  const headmasterBox = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (headmasterError) {
      if (headmasterBox?.current) {
        headmasterBox.current.scrollIntoView();
      }
    }
  }, [headmasterError]);

  return (
    <Box ref={headmasterBox}>
      <Typography variant={'h2'}>{t('label')}</Typography>
      <Divider sx={{ opacity: 0, m: 1 }} />
      <LoadingSkeleton loading={teacherStaffLoading} variant={'rounded'}>
        <Autocomplete
          disablePortal
          value={headmaster?.id != null ? headmaster : null}
          options={teachers ?? []}
          getOptionLabel={(t) => t.fullName ?? ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: isMobile ? '100%' : 300 }}
          onChange={(_, value) => {
            dispatch(
              updateTeacherStaffAction({ headmaster: value } as TeacherStaffDto)
            );
          }}
          onOpen={(_) => {
            dispatch(
              reloadTeachers({
                addressCheckInstitutionId,
              })
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    [`&.${inputLabelClasses.focused}`]: {
                      color: (theme) => theme.palette.text.primary,
                    },
                  },
                },
              }}
              label={t('label')}
              variant="outlined"
              error={!!headmasterError}
              helperText={headmasterError?.errorMessages
                ?.map((i) => t('error.' + i))
                ?.join('. ')}
            />
          )}
        />
      </LoadingSkeleton>
    </Box>
  );
};

const SchoolBookManagerAutocomplete: FC<{
  isMobile: boolean;
  addressCheckInstitutionId: string;
}> = ({ isMobile, addressCheckInstitutionId }) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherList.schoolBookManagers',
  });

  const [schoolBookManagers] = useObservable(schoolBookManagers$);
  const [teacherStaffLoading] = useObservable(teacherStaffLoading$);
  const [teachers] = useObservable(teachers$);

  const [schoolBookManagerError] = useObservable(
    teacherStaffValidationErrors$('schoolBookManagers')
  );
  const [headmasterError] = useObservable(
    teacherStaffValidationErrors$('headmaster')
  );

  const schoolBookManagerBox = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (schoolBookManagerError) {
      if (schoolBookManagerBox?.current && !headmasterError) {
        schoolBookManagerBox.current.scrollIntoView();
      }
    }
  }, [schoolBookManagerError, schoolBookManagers]);

  return (
    <Box ref={schoolBookManagerBox}>
      <Typography variant={'h2'}>{t('label')}</Typography>
      <Divider sx={{ opacity: 0, m: 1 }} />
      <LoadingSkeleton loading={teacherStaffLoading} variant={'rounded'}>
        <Autocomplete
          disablePortal
          value={schoolBookManagers ?? []}
          multiple={true}
          options={teachers ?? []}
          getOptionLabel={(t) => t.fullName ?? ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: isMobile ? '100%' : 600 }}
          onChange={(_, value) => {
            dispatch(
              updateTeacherStaffAction({
                schoolBookManagers: value,
              } as TeacherStaffDto)
            );
          }}
          onOpen={() => {
            dispatch(
              reloadTeachers({
                addressCheckInstitutionId,
              })
            );
          }}
          renderInput={(params: AutocompleteRenderInputParams) => {
            return (
              <TextField
                {...params}
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: {
                      [`&.${inputLabelClasses.focused}`]: {
                        color: (theme) => theme.palette.text.primary,
                      },
                    },
                  },
                }}
                label={t('label')}
                variant="outlined"
                error={!!schoolBookManagerError}
                helperText={schoolBookManagerError?.errorMessages
                  ?.map((i) => t('error.' + i))
                  ?.join('. ')}
              />
            );
          }}
        />
      </LoadingSkeleton>
    </Box>
  );
};

const TeacherListTable: FC<{
  isMobile: boolean;
  addressCheckInstitutionId: string;
}> = ({ isMobile, addressCheckInstitutionId }) => {
  const theme = useTheme();

  const [teacherStaffLoading] = useObservable(teacherStaffLoading$);
  const [teachersLoading] = useObservable(teachersLoading$);
  const [notSelected, setNotSelected] = React.useState<readonly number[]>([]);
  const [originalTeachers] = useObservable(originalTeachers$);

  const [editMode] = useObservable(teacherStaffEditMode$);

  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherList.teacherTable',
  });

  useEffect(() => {
    if (!editMode) {
      setNotSelected([]);
    }
  }, [editMode]);

  const isSelected = (id: number) => notSelected?.indexOf(id) === -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = notSelected.indexOf(id);

    const ids = [...notSelected];

    if (selectedIndex < 0) {
      ids.push(id);
    } else {
      ids.splice(selectedIndex, 1);
    }

    setNotSelected(ids);

    const updatedTeachers =
      originalTeachers?.filter(
        (teacher) => !ids.includes(Number(teacher.id))
      ) ?? [];
    dispatch(
      updateTeacherStaffAction({
        teachers: updatedTeachers,
      } as TeacherStaffDto)
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'}>{t('label')}</Typography>
        <RegisterTeacher isMobile={isMobile} />
      </Box>

      <Accordion
        elevation={0}
        sx={{
          '&::before': {
            display: 'none',
          },
          border: 0,
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.light,
          borderRadius: 1,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
            <Article color={'primary'}></Article>
            <Typography variant={'h4'}>{t('info.label')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          dangerouslySetInnerHTML={{
            __html: sanitize(t('info.formattedText')),
          }}
        />
      </Accordion>

      <LoadingSkeleton loading={teacherStaffLoading}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: theme.palette.grey.A100,
            }}
          >
            <TableRow sx={{ p: 0, m: 0 }}>
              <TableCell align={'center'}>
                <Typography variant={'h4'}>
                  <CheckBox
                    sx={{
                      verticalAlign: 'middle',
                      mr: 1,
                    }}
                  />
                  {t('isTeacher.label')}&nbsp;
                  {`(${originalTeachers?.length - notSelected?.length})`}
                </Typography>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'h4'}>
                    <Person
                      sx={{
                        verticalAlign: 'middle',
                        mr: 1,
                      }}
                    />
                    {t('name.label')}
                  </Typography>
                  <Tooltip title={t(`refresh.tooltip`)} placement={'top'}>
                    <LoadingButton
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                      }}
                      onClick={() => {
                        dispatch(
                          reloadTeachers({
                            addressCheckInstitutionId,
                          })
                        );
                      }}
                      loading={teachersLoading}
                      variant={isMobile ? 'text' : 'contained'}
                      endIcon={isMobile ? null : <Refresh />}
                    >
                      {isMobile ? <Refresh /> : t('refresh.label')}
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!originalTeachers.length ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {t('noTeachersInfo.label')}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              originalTeachers.map((row) => {
                const isItemSelected = isSelected(row?.id as number);
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, row?.id as number)}
                    aria-checked={isItemSelected}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: theme.palette.error.light,
                      '&.Mui-selected': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    <StyledTableCell padding="checkbox" align={'center'}>
                      <Checkbox color="primary" checked={isItemSelected} />
                    </StyledTableCell>
                    <StyledTableCell>{row.fullName}</StyledTableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </LoadingSkeleton>
    </Box>
  );
};
